.scroll {
  position: relative;
  display: flex;
  max-width: auto;
  overflow: hidden;
}

/* .scroll div {
  white-space: nowrap;
  animation-name: scroll;
  animation-iteration-count: infinite;
  animation-duration: 120s;
  animation-timing-function: linear;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-20%);
  }
} */
